<template>
    <page-title
        title="日時未定予定アラート"
        icon="bi-bell"
    >
        <router-link :to="{name: 'Schedule'}" class="btn btn-outline-primary">
            <i class="bi bi-reply"></i> スケジュールへ戻る
        </router-link>
    </page-title>

    <section class="section">
        <div class="alert alert-danger" role="alert">
            <i class="bi bi-exclamation-triangle me-2"></i>標準実施日の90日以内になりましたが、スケジュールに登録されていません。今すぐ確認してください。
        </div>
        <div class="text-end mb-3">
            <router-link :to="{name: 'UndecidedSchedule'}">
                日時未定予定の全リストはこちらから
            </router-link>
        </div>

        <table class="table  table-striped mb-4">
            <thead>
                <tr class="table-primary">
                    <th class="col-3 text-center">標準実施日</th>
                    <th class="col-6 text-center">学校名</th>
                    <th class="col-6 text-center">予定名</th>
                    <th class="col-4 text-center">昨年実施日</th>
                    <th class="col-5"></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="text-center">9/20</td>
                    <td class="text-center">北部小</td>
                    <td class="text-center">運動会</td>
                    <td class="text-center">2021.09.21</td>
                    <td class="text-center">
                        <button class="btn btn-primary me-4" @click="openModal">本登録...</button>
                        <button class="btn btn-outline-danger me-4" @click="$refs.confirm_cancel.show()">中止...</button>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">9/25</td>
                    <td class="text-center">南部小</td>
                    <td class="text-center">運動会</td>
                    <td class="text-center">2021.09.21</td>
                    <td class="text-center">
                        <button class="btn btn-primary me-4" @click="openModal">本登録...</button>
                        <button class="btn btn-outline-danger me-4" @click="$refs.confirm_cancel.show()">中止...</button>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">9/25</td>
                    <td class="text-center">北部小</td>
                    <td class="text-center">遠足</td>
                    <td class="text-center">2021.11.19</td>
                    <td class="text-center">
                        <button class="btn btn-primary me-4" @click="openModal">本登録...</button>
                        <button class="btn btn-outline-danger me-4" @click="$refs.confirm_cancel.show()">中止...</button>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">9/25</td>
                    <td class="text-center">中央小</td>
                    <td class="text-center">運動会</td>
                    <td class="text-center">2021.09.28</td>
                    <td class="text-center">
                        <button class="btn btn-primary me-4" @click="openModal">本登録...</button>
                        <button class="btn btn-outline-danger me-4" @click="$refs.confirm_cancel.show()">中止...</button>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">9/30</td>
                    <td class="text-center">東部中</td>
                    <td class="text-center">体育祭</td>
                    <td class="text-center">2021.09.28</td>
                    <td class="text-center">
                        <button class="btn btn-primary me-4" @click="openModal">本登録...</button>
                        <button class="btn btn-outline-danger me-4" @click="$refs.confirm_cancel.show()">中止...</button>
                    </td>
                </tr>
                <tr>
                    <td class="text-center">9/30</td>
                    <td class="text-center">中央小</td>
                    <td class="text-center">林間学校</td>
                    <td class="text-center">2021.09.30</td>
                    <td class="text-center">
                        <button class="btn btn-primary me-4" @click="openModal">本登録...</button>
                        <button class="btn btn-outline-danger me-4" @click="$refs.confirm_cancel.show()">中止...</button>
                    </td>
                </tr>

            </tbody>
            <tfoot></tfoot>
        </table>
    </section>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    スケジュール詳細
                                </h4>
                                <button type="button" class="btn-close" @click="closeModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group col-md-4 mb-5">
                                    <select class="form-select" v-model="schedule_type">
                                        <option>撮影</option>
                                        <option>打ち合わせ</option>
                                        <option>予備日</option>
                                        <option>その他</option>
                                    </select>
                                </div>

                                <div class="row align-items-end mb-5">
                                    <div class="form-group col-md-5">
                                        <label>学校名</label>
                                        <form-select-search
                                            :options="schools"
                                            v-model="school"
                                        ></form-select-search>
                                    </div>
                                    <div class="form-group col-md-8">
                                        <label>スケジュール名</label>
                                        <input type="text" class="form-control" v-if="free_input ==='yes'">
                                        <select class="form-select" v-else>
                                            <option>入学式</option>
                                            <option>運動会</option>
                                            <option>修学旅行</option>
                                            <option>定期演奏会</option>
                                            <option>遠足</option>
                                            <option>林間学校</option>
                                            <option>卒業式</option>
                                            <option>単発</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="form-check form-control">
                                            <input
                                                class="form-check-input ms-1 me-1"
                                                type="checkbox"
                                                id="flexCheckDefault3"
                                                v-model="free_input"
                                                true-value="yes"
                                                false-value="no"
                                            >
                                            <label class="form-check-label" for="flexCheckDefault3">
                                                自由入力
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row g-0 align-items-center mb-2">
                                    <div class="col-md-4">
                                        <input type="date" class="form-control">
                                    </div>

                                    <template v-if="showTimes">
                                        <template v-if="is_unfixed === 'yes'">
                                            <div class="col-md-4 ms-3">
                                                <select class="form-select">
                                                    <option>AM</option>
                                                    <option>PM</option>
                                                    <option>放課後</option>
                                                </select>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-md-4 ms-3">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" min="0" max="23" step="1">
                                                    <span class="input-group-text">:</span>
                                                    <input type="number" class="form-control" min="0" max="59" step="1">
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-center">〜</div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" min="0" max="23" step="1">
                                                    <span class="input-group-text">:</span>
                                                    <input type="number" class="form-control" min="0" max="59" step="1">
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-if="has_several_days === 'yes'">
                                        <div class="col-md-4 ms-3" v-if="is_all_day === 'no' && is_unfixed === 'no'">
                                            <div class="input-group">
                                                <input type="number" class="form-control" min="0" max="23" step="1">
                                                <span class="input-group-text">:</span>
                                                <input type="number" class="form-control" min="0" max="59" step="1">
                                            </div>
                                        </div>
                                        <div class="col-md-1 text-center">〜</div>
                                        <div class="col-md-4 me-3">
                                            <input type="date" class="form-control">
                                        </div>
                                        <div class="col-md-4" v-if="is_all_day === 'no' && is_unfixed === 'no'">
                                            <div class="input-group">
                                                <input type="number" class="form-control" min="0" max="23" step="1">
                                                <span class="input-group-text">:</span>
                                                <input type="number" class="form-control" min="0" max="59" step="1">
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <div class="row align-content-end justify-content-end mb-3">
                                    <div class="form-group col-md-3">
                                        <div class="form-check form-control">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input ms-1 me-1"
                                                    type="checkbox"
                                                    v-model="is_unfixed"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                未定
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <div class="form-check form-control">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input ms-1 me-1"
                                                    type="checkbox"
                                                    v-model="is_all_day"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                終日
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <div class="form-control">
                                            <label>
                                                <input
                                                    class="form-check-input ms-2"
                                                    type="checkbox"
                                                    v-model="has_several_days"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                <span class="ms-1">複数日</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col-md-4 mb-2">
                                    <label>雨天時対応</label>
                                    <select class="form-select" v-model="rainy">
                                        <option>雨天決行</option>
                                        <option>雨天延期</option>
                                        <option>雨天中止</option>
                                    </select>
                                </div>
                                <template v-if="rainy === '雨天延期'">
                                    <div class="row g-0 align-items-center mb-2">
                                        <div class="col-md-4">
                                            <input type="date" class="form-control">
                                        </div>

                                        <template v-if="rainyShowTimes">
                                            <template v-if="is_unfixed_rainy === 'yes'">
                                                <div class="col-md-4 ms-3">
                                                    <select class="form-select">
                                                        <option>AM</option>
                                                        <option>PM</option>
                                                        <option>放課後</option>
                                                    </select>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <div class="col-md-4 ms-3">
                                                    <div class="input-group">
                                                        <input type="number" class="form-control" min="0" max="23" step="1">
                                                        <span class="input-group-text">:</span>
                                                        <input type="number" class="form-control" min="0" max="59" step="1">
                                                    </div>
                                                </div>
                                                <div class="col-md-1 text-center">〜</div>
                                                <div class="col-md-4">
                                                    <div class="input-group">
                                                        <input type="number" class="form-control" min="0" max="23" step="1">
                                                        <span class="input-group-text">:</span>
                                                        <input type="number" class="form-control" min="0" max="59" step="1">
                                                    </div>
                                                </div>
                                            </template>
                                        </template>
                                    </div>
                                    <div class="row align-content-end justify-content-end mb-5">
                                        <div class="form-group col-md-3">
                                            <div class="form-check form-control">
                                                <label class="form-check-label">
                                                    <input
                                                        class="form-check-input ms-1 me-1"
                                                        type="checkbox"
                                                        v-model="is_unfixed_rainy"
                                                        true-value="yes"
                                                        false-value="no"
                                                    >
                                                    未定
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-3">
                                            <div class="form-check form-control">
                                                <label class="form-check-label">
                                                    <input
                                                        class="form-check-input ms-1 me-1"
                                                        type="checkbox"
                                                        v-model="is_all_day_rainy"
                                                        true-value="yes"
                                                        false-value="no"
                                                    >
                                                    終日
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="row mt-5 mb-5">
                                    <div class="col-md-3 text-center">
                                        <label class="col-form-label title">日程確認</label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="date" class="form-control" v-model="checked_date">
                                    </div>
                                    <div class="col-md-2">
                                        <button class="btn btn-outline-primary" @click="checkToday">今日</button>
                                    </div>
                                </div>
                                <div class="row mb-5">
                                    <div class="col-md-18">
                                        <div class="row mb-2">
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label title">カメラマン</label>
                                            </div>
                                            <div class="col-md-4">
                                                <input type="number" class="form-control">
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"></div>
                                            <div class="col-md-6">
                                                <select class="form-select">
                                                    <option>未定</option>
                                                    <option>片岡高志</option>
                                                    <option>奥村明生</option>
                                                    <option>江口悠斗</option>
                                                    <option>鈴木一郎</option>
                                                    <option>佐藤二郎</option>
                                                    <option>北島三郎</option>
                                                    <option>伊藤四郎</option>
                                                    <option>野口五郎</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <div class="form-control">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input ms-2" type="checkbox" value="">
                                                        カメラマン連絡済
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"></div>
                                            <div class="col-md-6">
                                                <select class="form-select">
                                                    <option>未定</option>
                                                    <option>片岡高志</option>
                                                    <option>奥村明生</option>
                                                    <option>江口悠斗</option>
                                                    <option>鈴木一郎</option>
                                                    <option>佐藤二郎</option>
                                                    <option>北島三郎</option>
                                                    <option>伊藤四郎</option>
                                                    <option>野口五郎</option>
                                                </select>
                                            </div>
                                            <div class="form-group col-md-6">
                                                <div class="form-control">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input ms-2" type="checkbox" value="">
                                                        カメラマン連絡済
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 bg-light" v-if="free_input === 'no'">
                                        <div class="col-md">
                                            <div class="border-bottom">
                                                <div>学校担当:吉岡孝二</div>
                                                <div>販売：あり  集合写真：買取販売</div>
                                            </div>
                                            <div>
                                                <div>2021.04.14：坂野</div>
                                                <div>2020.04.13：坂野</div>
                                                <div>2019.04.11：坂野</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center mb-4">
                                    <div class="col-md-3 text-center">
                                        <label class="col-form-label title">社用車</label>
                                    </div>
                                    <div class="col">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                            <label class="form-check-label" for="inlineCheckbox1">フ</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                            <label class="form-check-label" for="inlineCheckbox2">タ</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                                            <label class="form-check-label" for="inlineCheckbox3">キャ</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center mb-4">
                                    <div class="col-md-3 text-center">
                                        <label class="col-form-label title">移動手段</label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div v-if="drop_down" class="mb-4">
                                    <div class="mb-4">
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center">
                                                <label class="col-form-label title">備品</label>
                                            </div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(小1段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(3段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(大1段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(板)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">枚</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(2段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ストロボ</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">セット</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <div class="mb-2">
                                                <input type="text" placeholder="場所" class="form-control">
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md">
                                                    <input type="text" placeholder="先方窓口" class="form-control">
                                                </div>
                                                <div class="col-md">
                                                    <input type="text" placeholder="連絡先電話番号" class="form-control">
                                                </div>
                                            </div>
                                            <div>
                                                <textarea class="form-control" rows="5" placeholder="メモ"></textarea>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="mb-3">
                                                <button class="btn btn-outline-dark">ファイルを選択...</button>
                                            </div>
                                            <div class="mb-1"><i class="bi bi-file-earmark-image"></i> プログラム</div>
                                            <div class="mb-1"><i class="bi bi-file-earmark-image"></i> 配置図</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-end">
                                    <button class="btn btn-outline-primary" @click="toggleDrop">
                                        <i class="bi" :class="icon"></i> {{ drop_down_text }}
                                    </button>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-primary" @click="closeModal">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_cancel" @ok="closeModal()">
        <p>この行事を中止してよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import ConfirmDialog from '@/components/tools/ConfirmDialog';


export default {
    name: 'AlertUndecidedSchedule',
    components: {
        PageTitle,
        ConfirmDialog,
        FormSelectSearch
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            modal: false,
            modal_title: '',
            type: 'カメラマン',
            schedule_type: '撮影',
            free_input: 'no',
            rainy:'雨天延期',
            checked_date: null,
            is_all_day: 'no',
            has_several_days: 'no',
            is_unfixed: 'no',
            is_all_day_rainy: 'no',
            is_unfixed_rainy: 'no',
            times: ['---', '0:00', '0:15', '0:30', '0:45', '1:00', '1:15', '1:30', '1:45', '2:00', '2:15', '2:30', '2:45', '3:00', '3:15', '3:30', '3:45', '4:00', '4:15', '4:30', '4:45', '5:00', '5:15', '5:30', '5:45', '6:00', '6:15', '6:30', '6:45', '7:00', '7:15', '7:30', '7:45', '8:00', '8:15', '8:30', '8:45', '9:00', '9:15', '9:30', '9:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45', '12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45', '16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00', '20:15', '20:30', '20:45', '21:00', '21:15', '21:30', '21:45', '22:00', '22:15', '22:30', '22:45', '23:00', '23:15', '23:30', '23:45'],
            school: '北部小',
            schools: [
                {key:'1', label:'北部小'},
                {key:'2', label:'東部小'},
                {key:'3', label:'中央小'},
                {key:'4', label:'西部小'},
                {key:'5', label:'南部中'},
            ],
            icon: 'bi-chevron-down',
            drop_down: false,
            drop_down_text: '詳細情報を表示',
        }
    },
    computed: {
        showTimes() {
            if (this.is_all_day === 'yes') {
                return false;

            } else {
                if (this.has_several_days === 'yes') {
                    return false;
                }
            }

            return true;
        },
        rainyShowTimes() {
            if (this.is_all_day_rainy === 'yes') {
                return false;

            }

            return true;
        }
    },
    methods: {
        save() {
            this.closeModal();
            this.showMessage('本登録しました');
            this.$router.push({name: 'Schedule'});
        },
        openModal() {
            this.modal = true;

        },
        closeModal() {
            this.modal = false;
        },
        //一括操作の出し入れ
        toggleDrop() {
            if (this.drop_down) {
                this.drop_down = false;
                this.icon = 'bi-chevron-down';
                this.drop_down_text = '詳細情報を表示';
            } else {
                this.drop_down = true;
                this.icon = 'bi-chevron-up'
                this.drop_down_text = '詳細情報を隠す';
            }
        },
        checkToday() {
            let dt = new Date();
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.checked_date = result;
        }
    }
}
</script>

<style scoped>

</style>
